<template>
  <div class="register_container">
    <van-popup class="popup" v-model="overf" style="width: 70%;">
      <div class="con">若号满，不设现场取号。</div>
      <div class="button">
        <van-button @click="conf()" type="primary">确认</van-button>
      </div>
    </van-popup>
    <van-popup class="popup" v-model="consult" style="width: 70%;">
      <div class="con">星期一，星期四出诊。

      </div>
      <div class="button">
        <van-button @click="conf()" type="primary">确认</van-button>
      </div>
    </van-popup>
    <van-popup class="popup" v-model="hot" style="width: 70%;">
      <div class="con">24小时内体温≧37.3°C，请到儿科发热门诊就诊</div>
      <div class="button">
        <van-button @click="conf()" type="primary">确认</van-button>
      </div>
    </van-popup>
    <!-- 搜索框 -->
    <div class="search">
      <form action="/">
        <van-search v-model="value" show-action placeholder="请输入搜索科室或医生" @input="onSearch" @cancel="onCancel"
          @clear="onClear" @focus="onFocus" />
      </form>
    </div>
    <!-- 搜索结果 -->
    <div class="search_list" v-if="searchShow === true">
      <van-cell-group>
        <van-cell title="科室" class="title" />

        <van-cell v-for="item in searchDeptList" :title="item.deptName" :key="item.deptCode" class="item"
          @click="navDept(item.deptCode)" />
      </van-cell-group>
      <van-cell-group>
        <van-cell title="医生" class="title" />
        <div class="doctorItem" v-for="item in searchDoctorList" @click="navDoctor(item.doctorCode, item.deptCode)"
          :key="item.doctorCode">
          <div class="img">
            <van-image width="90" height="120" :src="item.pictureUrl" fit="contain" v-if="item.pictureUrl" />
            <van-image width="60" height="50" :src="'http://tssywx.tssrmyy.com/' + item.doctorCode + '.jpg'"
              fit="contain" />
          </div>
          <div class="content">
            <div>{{ item.doctorName }}|{{ item.doctorTitle }}</div>
            <div class="deptName">{{ item.deptName }}</div>
          </div>
        </div>
        <!-- <van-cell v-for="item in searchDoctorList"
                  :title="item.doctorName"
                  :key="item.doctorCode"
                  class="item"
                  @click="navDoctor(item.doctorCode)">
          {{item.deptName}}
        </van-cell> -->
      </van-cell-group>
    </div>
    <!-- 科室列表 -->
    <!-- <div class="dept_list"
         v-if="searchShow === false">
      <van-cell-group>
        <van-cell v-for="item in deptList"
                  :title="item.deptName"
                  :key="item.deptCode"
                  icon="friends-o"
                  is-link
                  @click="navDept(item.deptCode)" />
      </van-cell-group>
    </div> -->
    <van-tree-select :items="deptList" v-if="searchShow === false" :main-active-index="mainActiveIndex"
      :active-id="activeId" @click-nav="onNavClick" @click-item="onItemClick" />
    <router-view />
  </div>
</template>

<script>
import { jsonPost, formPost } from "@/baseAPI";
import { TreeSelect } from "vant";
import qs from "qs";
import { ref } from 'vue';
import { type } from 'os';
export default {
  name: "Register",
  data () {
    return {
      value: "",
      searchShow: false,
      deptList: [],
      searchDeptList: [],
      searchDoctorList: [],
      mainActiveIndex: 0,
      activeId: 0,
      overf: false,
      consult: false,
      hot: false,
      deptCode: '',
      timeSlot: "",
      levelCode: ''
    };
  },
  created () {
    if (window.localStorage.getItem("openId") === null) {
      this.getCode();
    } else {
      this.getDept();
    }
    this.getToday()
  },
  methods: {
    getToday () {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      let currentdate = year + "-" + month + "-" + strDate;
      this.timeSlot = currentdate;
    },
    onNavClick (index) {
      this.mainActiveIndex = index;
      this.levelCode = this.deptList[index].levelCode
    },
    onItemClick (data) {
      this.activeId = data.id;
      if (data.id == '0202010104') {
        this.overf = true
        this.deptCode = data.id
      } else if (data.id == '0202010103') {
        this.consult = true
        this.deptCode = data.id
      } else if (data.id == '0000000000') {
        this.hot = true
        this.deptCode = data.id
      } else {
        setTimeout(() => {
          this.$router.push({
            path: "/register/lists",
            query: {
              deptCode: data.id,
              levelCode: this.levelCode
            }
          });
        }, 200);
      }
    },
    conf () {
      this.overf = false
      this.consult = false
      if (this.activeId == '0000000000') {
        this.hot = false
      } else {
        setTimeout(() => {
          this.$router.push({
            path: "/register/lists",
            query: {
              deptCode: this.deptCode
            }
          });
        }, 200);
      }

    },
    // 获取code
    getCode () {
      let local = window.location.href;
      let appid = "wxcdae494f7e9422e7";
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent
        (local)
        }&response_type=code&scope=snsapi_base&state=123&#wechat_redirect`;
      // window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxcdae494f7e9422e7&redirect_uri=http://tssywx.tssrmyy.com&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect"
      let str = window.location.href;
      let code = str.split("code=")[1].split("&")[0];
      this.code = code
      this.login(code);
    },
    //获取openId
    async login (code) {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true
      });
      let data = qs.stringify({
        code: code
      });
      const { data: res } = await formPost("/wxapp/login", data);
      this.$toast.clear();
      if (res.code === 0) {
        window.localStorage.setItem("openId", res.data.openid);
        this.getDept();
      } else {
        this.$toast.fail(res.msg);
      }
    },
    // 获取科室
    async getDept () {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      let deptCode = qs.stringify({
        levelCode: 0
      });
      const { data: res } = await formPost("/wxapp/his/getDept", deptCode);
      this.$toast.clear();
      if (res.code === 0) {

        this.deptList = JSON.parse(JSON.stringify(res.data.levelItems).replace(/"levelName"/g, '"text"').replace(/"item"/g, '"children"').replace(/"deptName"/g, '"text"').replace(/"deptCode"/g, '"id"'))
      } else {
        this.$toast.fail(res.msg);
      }
    },
    //获取搜索结果
    async getSearchList (searchStr) {
      let deptName = qs.stringify({
        deptName: searchStr
      });
      let doctorName = qs.stringify({
        doctorName: searchStr
      });
      const { data: re } = await formPost("/wxapp/his/getDept", deptName);
      const { data: res } = await formPost("/wxapp/his/getStaff", doctorName);
      if (re.code !== 0) {
        this.searchDeptList = [];
      } else {
        this.searchDeptList = re.data.item;
      }
      if (res.code !== 0) {
        this.searchDoctorList = [];
      } else {
        this.searchDoctorList = res.data.item;
      }
    },
    onSearch (val) {
      this.value = val.trim();
      if (this.value.length !== 0) {
        this.searchShow = true;
        this.getSearchList(this.value);
      } else {
        this.searchDeptList = [];
        this.searchDoctorList = [];
        this.searchShow = false;
      }
    },
    onCancel () {
      this.value = "";
      this.searchShow = false;
    },
    onClear () {
      this.value = "";
      this.searchShow = false;
    },
    onFocus () {
      this.searchShow = true;
    },
    navDept (deptCode) {
      this.$router.push({
        path: "/register/lists",
        query: {
          deptCode
        }
      });
    },
    navDoctor (doctorCode, deptCode) {
      console.log("deptCode", deptCode);
      this.$router.push({
        path: "/register/doctor",
        query: {
          doctorCode,
          deptCode,
          dateIndex: "0",
          tabIndex: "0",
          timeSlot: this.timeSlot
        }
      });
    }
  }
};
</script>

<style lang="less" scope>
.van-tree-select {
  height: 86vh !important;
}

.register_container {
  padding-bottom: 2.5rem;
  height: 100vh;

  .popup {
    .con {
      padding: 10px 30px;
      font-size: 32px;
    }

    button {
      width: 100%;
      background-color: #1cd66c;
      color: #eee;
    }
  }
}

.register_container .search {
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
}

.register_container .search .van-field__control[type='search'] {
  height: 60px !important;
  font-size: 36px !important;
}

.register_container .search .van-field__left-icon .van-icon {
  font-size: 36px !important;
  line-height: 64px !important;
}

.register_container .search .van-search__action {
  font-size: 36px !important;
  padding: 0 20px !important;
}

.register_container .search_list .title {
  background-color: #1989fa;
  color: #eee;
}

.register_container .search_list .title:after {
  border-bottom: 0;
}

.register_container .search_list .title .van-cell__left-icon {
  font-size: 1.6667rem;
}

.register_container .search_list {
  .doctorItem {
    padding: 7px 10px;
    font-size: 35px;
    border-bottom: 1px solid #ccc;
    font-weight: normal;
    display: flex;
    align-items: center;

    .deptName {
      color: #ccc;
      font-size: 32px;
    }
  }
}

.register_container .search_list .item .van-cell__left-icon,
.register_container .search_list .item .van-cell__right-icon {
  font-size: 1.5rem;
  line-height: 2.3333rem;
}

.register_container .dept_list .van-cell {
  font-size: 1.5rem;
  height: 4.1667rem;
  line-height: 2.75rem;
}

.register_container .dept_list .van-cell .van-cell__left-icon,
.register_container .dept_list .van-cell .van-cell__right-icon {
  font-size: 1.6667rem;
  line-height: 2.6667rem;
  color: #1989fa;
}
</style>